.table-wrapper {
    overflow-x: auto;
    width: 100%;
}

table {
    border-collapse: collapse;
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
    tr, td {
        white-space: nowrap;
    }
}

@media screen and (max-width: 480px) {
    th, td {
        text-align: center;
        padding: 10px;
    }

    th {
        background-color: #f2f2f2;
    }

    .table-wrapper {
        overflow-x: auto;
    }
}