.ui.form.account-form .input-right .mono {
  display: block;
  margin-top: 0.75em;
}

.ui.form.account-form .field.checkbox {
  padding-top: 0.75em;
  @media (max-width: 420px) {
    width: 54.25% !important;
  }
}

.ui.form .nine.wide.field {
  @media (max-width: 420px) {
    width: 54.25% !important;
  }
}

.ui.form .seven.wide.field {
  @media (max-width: 420px) {
    width: 45.75% !important;
  }
}

.ui.form .seven.wide.field input {
  -webkit-appearance: none;
  -moz-appearance:textfield;
}

.ui.form.account-form .fields.no-margin {
  margin-bottom: 0 !important;
}

@media (max-width: 420px) {
  .ui.form .seven.wide.field input,
  .ui.form .nine.wide.field input {
    font-size: 0.9em;
    padding: 10px 5px;
  }
}
