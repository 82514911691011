.logo {
  height: 60px;
  width: 125px;
  text-align: center;
  padding: 15px 30px 0 0;
}

.logo img {
  height: 32px;
}
