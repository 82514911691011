@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:300,400,500');

body {
  height: auto !important;
  margin: 0;
  font-family: 'Jellee', sans-serif;
}

nav {
  position: fixed;
  top: 60px;
  width: 125px;
  min-height: 100%;
  transition: transform 0.5s ease;
  z-index: 101;
}

nav.open {
  transform: translate(0, 0);
}

nav.closed {
  transform: translate(-125px, 0);
}

nav .menu {
  min-height: 100vh !important;
  border: none !important;
  border-radius: 0 !important;
}

nav .menu .item:last-child {
  border-radius: 0 !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15) !important;
}

.open .ui.menu {
  font-family: 'Jellee',  sans-serif;
  background-color: #f5f5f5;
}

.ui.fluid.menu > .item {
  background-color: #fcde02;
}
.ui.fluid.menu > .item:hover {
  color: #fcde02;
  background-color: #1d1d1b;
}
.open .ui.blue.menu .active.item,
.open .ui.menu .active.item {
  color: white;
  border-color: #1d1d1b;
  background-color: #1d1d1b;
}

.open .ui.menu .active.item {
  color: white!important;
  box-shadow: inset 0 1px 4px 0 rgba(34, 36, 38, 0.12) !important;
}
.container-raised-desktop {
  display: block;
  background-color: white;
  padding: 1em 0 0 0;
  max-width: 700px;
  font-size: 1.14285714rem;
  line-height: 1.5;
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
}

.container-raised-desktop .unstackable .ui.fluid.primary.button:active {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.container-raised-desktop .ui.header,
.container-raised-desktop .ui.form,
.container-raised-desktop p {
  margin: 1rem;
}

.container-raised-desktop .ui.form {
  margin-bottom: 0;
}

.container-raised-desktop .form-submit {
  padding: 1em 0 0 0;
  text-align: right;
}

.container-raised-desktop .form-submit .button {
  margin: 0;
}

.container-full-page {
  display: block;
  background-color: #fffce8;
  font-size: 1.14285714rem;
  line-height: 1.5;
  padding: 0 1em 1em 1em;
}

.ui.message {
  max-width: 960px !important;
  margin: 1em auto;
  padding: 1em !important;
}

.ui.message .content {
  margin-top: 0.5em;
}

.container-full-page.flat {
  padding: 0;
}

.ui.primary.button, .ui.primary.button:focus, .ui.primary.button:active, .section__body .ui.button, .container-raised-desktop .ui.button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fcde02;
  background-color: #1d1d1b;
}

.ui.primary.button:hover, .section__body .ui.button:hover, .container-raised-desktop .ui.button:hover {
  color: #1d1d1b;
  background-color: #fcde02;
}
.ui.primary.button {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.ui.label,
.ui.header {
  font-weight: 400 !important;
}

.ui.button {
  border-radius: 16px;
  font-size: 1.2rem;
  font-weight: 600;
}
.ui.button:hover {
}
.ui.dropdown .menu .active.item {
  font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Jellee', sans-serif;
  font-weight: 400;
  margin: 0;
}
.ui.selection.dropdown, .required .ui input, button.ui, .ui.fluid.labeled input {
  border-radius: 0.3rem;
}
.ui.selection.dropdown:hover, .required .ui input:hover, button.ui:hover, .ui.input input:hover, .ui.transactions-list__wrapper:hover {

}
.ui.item.menu .item {
  position: relative;
  border: 1px solid #d4d4d5;
  font-size: 1.2rem;
  font-weight: 600;
}
.ui.item.menu  .item:hover, .ui.item.menu .active.item {
  color: #fcde02!important;
  background-color: #1d1d1b;
  border: 1px solid #1d1d1b!important;
}
.ui.item.menu .item, .ui.modal .ui.button, .ui.modal .ui.positive.button:hover {
  color: #1d1d1b!important;
  background-color: #fcde02;
  border: 1px solid #d4d4d5;
}
.ui.item.menu .item:hover, .ui.modal .ui.button:hover, .ui.modal .ui.positive.button {
  color: #fcde02!important;
  background-color: #1d1d1b;
}
.modal.account-form .unstackable.fields.last-field {
  flex-direction: column;
}
.modal.account-form .unstackable.fields.last-field .input-right {
  width: 100% !important;
}

.modal.account-form .ui.form .unstackable.fields.last-field .seven.field {
  width: 100% !important;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  button {
    width: auto;
  }
}

.modal.account-form .ui.form.account-form .fields:not(.unstackable.fields) {
  @media (max-width: 767px) {
    gap: 10px;
  }
}

.mono {
  font-family: 'Roboto Mono', monospace;
  color: #686869;
}

.positive {
  color: #21ba45;
}

.negative {
  color: #db2828;
}

.ui.form .field > label {
  font-weight: 500;
}

.container-full-page .container-header {
  padding: 1em;
  border-bottom: solid 1px #d4d4d5;
  border-radius: 5px 5px 0 0;
  background: #fffce8;
}

.container-full-page .container-footer > .ui.dropdown {
  margin-bottom: 1em;
}

.container-full-page .container-footer > .ui.dropdown:last-child {
  margin-bottom: 0;
}

.container-full-page .container-footer {
  padding: 1em;
  border-top: solid 1px #d4d4d5;
  border-radius: 0 0 5px 5px;
  background: #f9fafb;
}

.container-full-page .container-header > .ui.basic.buttons {
  background: #ffffff;
}
.ui.dropdown.categories {
  position: relative;
  display: block;
  margin: 0;
  box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
  background-color: white;
}
.ui.dropdown.categories .item {
  color: rgba(0,0,0,.95);
  border-top: 1px solid #fafafa;
  white-space: normal;
  word-wrap: normal;
  font-weight: 600;
  padding-right: 45px;
}
.ui.dropdown.categories .item:hover {
  background: rgba(0,0,0,.03);
}
.categories  .delete.icon {
  position: absolute;
  top: 20%;
  right: 16px;
  font-size: 22px;
}
.categories  .delete.icon:hover {
  opacity: 0.6;
}
.categories + .ui.add.multiple {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  @media (max-width: 500px) {
    padding-right: .35714286em;
  }
}
.categories + .ui.add.multiple div:first-child {
  margin-bottom: 10px;
}
.categories + .ui.add.multiple {
  padding-right: .35714286em;
}
@media (min-width: 500px) {
  .ui.item.menu .item:before, .ui.item.menu .item:after  {
    position: absolute;
    width: 33px!important;
    height: 33px!important;
  }
  .ui.item.menu .item.red:before {
    content: url(/public/Images/expensesBlack.svg);
    left: 12%;
    top: 20%;
    right: 12%;
  }
  .ui.item.menu .item.red:hover::before, .ui.item.menu .item.red.active::before {
    content: url(/public/Images/expensesYellow.svg);
  }
  .ui.item.menu .item.green:after {
    content: url(/public/Images/incomeBlack.svg);
    left: 12%;
    top: 22%;
    right: 12%;
  }
  .ui.item.menu .item.green:hover::after, .ui.item.menu .item.green.active::after {
    content: url(/public/Images/incomeYellow.svg);
  }
  .ui.item.menu .item.black:before {
    content: url(/public/Images/transferBlack.svg);
    left: 12%;
    top: 35%;
    right: 12%;
    background: none;
  }
  .ui.item.menu .item.black:hover::before, .ui.item.menu .item.black.active::before {
    content: url(/public/Images/transferYellow.svg);
  }
  .categories + .ui.add.multiple {
    flex-direction: row;
    justify-content: space-between;
  }
  .categories + .ui.add.multiple div:first-child {
    min-width: 50%;
  }
  .categories + .ui.add.multiple div:first-child {
    margin-bottom: 0;
  }
}
@media (min-width: 769px) {
  .container {
    max-width: calc(100% - 125px);
    margin-left: 125px;
    margin-top: 1em;
  }
}
@media (min-width: 768px) {
  body {
    background-color: #f5f5f5;
  }
  .container-raised-desktop {
    margin: 1em auto;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
  }
  .container-full-page {
    margin-left: 3px;
  }
  .ui.warning {
    margin: 1em 1em 0 !important;
  }
  .modal.account-form .unstackable.fields {
    flex-direction: row;
  }
  .modal.account-form .unstackable.fields .input-right {
    width: 44%!important;
  }
}

@media (min-width: 1194px) {
  nav {
    box-shadow: 0 2px 6px 0 rgba(34, 36, 38, 0.15),
    0 2px 18px 0 rgba(34, 36, 38, 0.17);
  }
  .container-full-page {
    max-width: 960px;
    margin: 1em auto;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
  }
}

@media (max-width: 767px) {
  .ui.grid {
    margin: 0;
  }

  .ui.grid .row .column {
    padding: 0;
  }

  .container-raised-desktop .form-submit {
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    padding: 1rem;
  }

  .container-full-page .ui.grid .row {
    padding: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  }

  .ui.warning {
    margin: 0 !important;
    box-shadow: none !important;
    padding: 1em !important;
  }

  .mt-settings .section__body {
    padding: 1em;
    border-top: solid 1px #d4d4d5;
  }
  .mt-settings .section__body .ui.form + div {
    @media (max-width: 520px) {
      flex-direction: column;

      button:first-child {
        margin-bottom: 7px;
      }
    }
  }
  .container-full-page .container-header {
    padding: 0;
    background: #ffffff;
    border-radius: 0;
    border-top: solid 1px #d4d4d5;
  }

  .container-full-page .container-header > .ui.basic.buttons {
    border: none;
    border-radius: 0;
    border-right: solid 1px #d4d4d5;
  }
}

.subscription-managment__title {
  padding: 30px 0;
}

.subscription-managment__subscriptions {
  display: flex;
  align-items: center;
}

.subscription-managment__subscriptions table {
  width: 100%;
}

.subscription-managment__subscriptions table tr td,
.subscription-managment__subscriptions table tr th {
  text-align: center;
  border: 1px solid;
  padding: 10px;
}

.subscription-managment__subscriptions table button {
  background: red;
  font-family: 'Jellee', sans-serif;
  font-weight: 400;
  font-size: 14px;
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  color: white;
}

.subscription-managment .error {
  color: red;
}

.wrapper-btn-cancel {
  display: flex;
  max-width: 960px;
  justify-content: flex-end;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.mt-6 {
  margin-top: 6rem;
}

.subscription-badge {
  padding-bottom: 25px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
}


.container-redirect {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}