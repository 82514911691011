header {
  position: sticky;
  top: 0;
  height: 62px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #1d1d1b;
  /*border-bottom: 1px solid rgba(34, 36, 38, 0.15);*/
  box-shadow: 0 2px 6px 0 rgba(34, 36, 38, 0.15),
    0 2px 18px 0 rgba(34, 36, 38, 0.17);
  z-index: 102;
}

header .ui.header {
  display: flex !important;
  flex-direction: row;
  vertical-align: middle;
  color: white;
  width: 100%;
  align-items: center;
}

header .ui.header h2 {
  font-weight: 300;
}

.ui.header:first-child {
  margin-top: 0;
}

.ui.header .icon {
  padding-top: 0;
  font-weight: 300;
}

header .content {
  flex: 1;
}

header .ui.header .icon {
  margin-top: 3px;
}

header .ui.header .subscription-badge {
  margin-right: 0;
  padding-bottom: 0;
}

@media (max-width: 520px) {
  header .ui.header h2 {
    font-size: 18px;
  }
}
