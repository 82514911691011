.container-login {
    padding-top: 20vh;
}

.form-wrapper {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 20px;
}

.text-center {
    text-align: center;
}

.new-toggle {
    margin-top: 3rem;
}

.subheading {
    margin: 2rem 0;
}

.error-label {
    color: #9f3a38;
    margin-top: 10px;
    font-family: Roboto Mono;
}

.container-login .ui.message {
    margin: 0 0 20px !important;
}

.forgot-password {
    margin-bottom: 15px;
    text-align: right;
}

.forgot-password span {
    text-decoration: underline;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    margin: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.modal-label {
    display: block;
    margin-bottom: 1rem;
}

.modal-input {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255,255,255,0);
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease,border-color .1s ease;
    margin-bottom: 5px;
    width: 100%;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-button {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.modal-button-primary {
    background-color: #007bff;
    color: #fff;
    border: 0;
    border-radius: 5px;
    font-family: Jellee, sans-serif;
}

.modal-button-secondary {
    background-color: #ccc;
    color: #333;
    border: 0;
    border-radius: 5px;
    font-family: Jellee, sans-serif;
}

@media(max-width: 768px) {
    .container-login .form-wrapper .ui.form .fields {
        margin: 0 -.5em 1em;
    }
}