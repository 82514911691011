.modal-container .wrapper-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-container .wrapper-buttons button {
    border: 0;
    padding: 10px 15px;
    font-size: 16px;
    font-family: 'Jellee', sans-serif;
    font-weight: 400;
    color: white;
    border-radius: 5px;
}

.modal-container .wrapper-buttons button:first-child {
    background: green;
}

.modal-container .wrapper-buttons button:last-child {
    background: red;
}