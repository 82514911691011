.mt-report .ct-chart {
  margin-top: 1em;
  margin-bottom: 1em;
}

.mt-report-expense-income .ct-series-a .ct-bar {
  stroke: #7cb342;
}

.mt-report-expense-income .ct-series-b .ct-bar {
  stroke: #f44336;
}

.mt-report-expense-income .ct-bar {
  stroke-width: 1em;
  stroke-opacity: 0.75;
}

.mt-report-expense-tags .ct-bar {
  stroke: #f44336;
  stroke-width: 1em;
  stroke-opacity: 0.75;
}

.chartist-tooltip {
  min-width: 3em;
  padding: 0.3em;
}

.chartist-tooltip:before {
  margin-left: -10px;
  border: 10px solid transparent;
}

.chartist-tooltip span {
  font-size: 14px;
}

.mt-report-net-worth .ct-point,
.mt-report-net-worth .ct-line {
  stroke: #7cb342;
}

.mt-report-net-worth .ct-area {
  fill: #7cb342;
}

.chartist-tooltip {
  background: #d4d4d5;
  border-radius: 3px;
}

.chartist-tooltip:before {
  border-top-color: #d4d4d5;
}

.mt-report-stats {
  padding: 1em;
  border-bottom: solid 1px;
  border-bottom-color: #d4d4d5;
}

.ct-labels .ct-label.ct-end {
  justify-content: flex-start !important;
  text-align: left !important;
  align-items: center !important;
  padding-top: 5px;
}

@media (max-width: 767px) {
  .ui.statistics {
    margin: 1em 0 0;
  }

  .mt-report-stats {
    padding-bottom: 0;
  }

  .ui.statistic {
    width: 100%;
  }

  .ui.statistic+.ui.statistic {
    margin: 0 1.5em 1em;
  }
}

@media (max-width: 380px) {
  .container-header .ui.basic.buttons .report-dropdown {
    padding: 11px 10px;
  }
}