.body-rtl {
    direction: rtl;
    .ui[class*="right labeled"].input > input {
        border-left-color: transparent !important;
        border-radius: 0 .28571429rem .28571429rem 0 !important;
        border-right-color: rgba(34,36,38,.15) !important;
    }
    .ui[class*="right labeled"].input > input + .label {
        border-radius: .28571429rem 0 0 .28571429rem;
    }
    .transaction-form-grid__column-wide {
        padding-left: 0.3em;
        padding-right: 0;
    }
    .transaction-form-grid__column-narrow {
        padding-left: 0;
        padding-right: 0.7em;
    }
    .transaction-item__edit > .ui.button {
        margin: 0 .75em 0 0;
    }
    .transaction-item__info {
        padding-left: 0;
        padding-right: 0.5em;
    }
    [textalign="right"] {
        text-align: right;
    }
    [textalign="left"] {
        text-align: left;
    }
    .ui.dropdown .menu > .item,
    .ui.form .field > label {
        text-align: right;
    }
    .account-widget-account__edit {
        padding: 0.75em 0 0.75em 0.75em;
    }
}